import React from "react"
import SEO from "components/seo"

const PrivacyPage = () => (
  <>
    <SEO
      title="Sourcery | Privacy Policy"
      description="The security and privacy of your code is the most important thing to us. Learn more about our full privacy policy."
    />

    <div className="bg-[#111111]">
      <div className="mx-auto px-6 pt-6 max-w-2xl">
        <h1 className="text-2xl text-left text-white 2xl:text-5xl md:text-4xl mt-28 lg:mt-40 lg:text-left">
          Privacy policy
        </h1>
        <p className="text-base text-left text-white lg:text-left opacity-40 mt-5">
          Effective as of September 21st 2023
        </p>
      </div>

      <div className="px-6 py-6 mx-auto max-w-2xl text-white">
        <div>
          <p>
            You{"'"}re probably here expecting a fairly lengthy document of
            legalese about how we do and don{"'"}t collect info and how you can
            delete any information we collect {"&"} store. Don{"'"}t worry -
            that
            {"'"}s all here - but before we dive into that we wanted to quickly
            give an overview of how your code is kept private via Sourcery, why
            you need a Token to get started, and what info we do and don{"'"}t
            collect when you use our IDE plugins, GitHub integration, or CLI.{" "}
            <br />
            <br />
            We{"'"}re a team of developers here at Sourcery and we know how
            important the privacy and security of your code is. That{"'"}s why
            we
            {"'"}re taking every step to ensure that your code is being kept
            private and that our analysis is being done fully locally as much as
            possible.
            <br />
            <br />
            In this page we{"'"}ll refer to two separate pieces of the Sourcery
            product - Sourcery Core and the Sourcery Coding Assistant. We break
            them out because the way we handle your code and conduct analysis
            are different between the two and because you can control how you
            opt into each of them separately
          </p>
        </div>

        <div className="mt-5">
          <h3 className="text-2xl py-6 mt-3">
            What are Sourcery Core and the Sourcery Coding Assistant
          </h3>
          <p>
            <b>Sourcery Core</b> is our original code quality review and
            improvement tool. It runs fully locally, analyzing your code and
            making suggestions and recommendations about how it can be improved
            (these are the underlined sections of code with suggestions you see
            in your IDE).
            <br />
            <br />
            The <b>Sourcery Coding Assistant</b> is our AI powered pair
            programmer which looks to assist you with tasks such as code
            reviews, troubleshooting code problems, generating docstrings or
            test, explaining code, etc. The Sourcery Coding Assistant uses third
            party Large Language Models to provide its functionality and
            requires us to be able to send messages and code context to those
            models to function. We collect message data (but not your code) to
            allow us to improve the quality of our responses.
            <br />
            <br />
            You can use Sourcery Core and the Sourcery Coding Assistant together
            or use them independently. We will never send any of your code off
            of your machine without you explicitly opting in to do so before
            using the Sourcery Coding Assistant.
          </p>
        </div>

        <div className="mt-5">
          <h3 className="text-2xl py-6 mt-3">
            In your IDE {"&"} with the Sourcery CLI
          </h3>
          <ul className="list-inside list-disc">
            <li className="mb-5">
              All analysis for Sourcery Core done fully locally. We don{"'"}t
              send any of your code to our servers or see any of your code in
              any way for this analysis.
            </li>
            <li className="mb-5">
              For the Sourcery Coding Assistant code sections and messages are
              sent to third-party Large Language Model (LLM) providers (such as
              OpenAI, Anthropic, etc) using their APIs. This data passes through
              our servers but we do not store any of your code. All LLM
              providers we work with do not use any of your code or messages to
              train their models and do not store any of your data for more than
              30 days.
              <br />
              <br />
              No code will ever be sent off of your device unless you explicitly
              opt into using the Sourcery Coding Assistant. Not opting into the
              Sourcery Coding Assistant does not restrict your ability to use
              Sourcery Core in any way.
            </li>
            <li className="mb-5">
              We collect basic usage statistics (eg. how many refactorings we
              {"'"}re suggesting, which types of refactorings are they, and did
              you accept them or not) and the messsages you send to the Coding
              Assistant so that we can continue to improve the Sourcery product
              and offer better suggestions in the future. You can opt out of our
              collection of this data by disabling telemetry in your IDE
              settings.
            </li>
            <li className="mb-5">
              You need to enter a token to verify that you have a Sourcery
              account with the right level of feature access (Open Source, Pro,
              or Team). You can get your token from your dashboard. Sourcery
              checks your token every 2 weeks, otherwise you can use Sourcery
              fully offline.
            </li>
          </ul>
        </div>

        <div>
          <h3 className="text-2xl py-6 mt-3">In GitHub</h3>
          <ul className="list-inside list-disc gap-3">
            <li className="mb-5">
              For GitHub cloud, our analysis is done on our servers,
              refactorings are proposed to your repo as a pull request, and then
              all of the code we were analyzing is immediately deleted. We do
              not store any of your code.
            </li>
            <li className="mb-5">
              We collect basic usage statistics (eg. how many refactorings we
              {"'"}re suggesting, which types of refactorings are they, and did
              you accept them or not) so that we can continue to improve the
              Sourcery product and offer better suggestions in the future.
            </li>
            <li className="mb-5">
              All the information we collect on the product usage is stored in
              Mixpanel and you can request that we delete it at any point by
              emailing us at info@sourcery.ai
            </li>
          </ul>
          At the end of the day we{"'"}re trying to do everything we can to
          offer you incredible improvements to your code while maintaining the
          privacy and security of your code base. If you have any questions
          about our approach please let us know at hello@sourcery.ai.
        </div>

        <div>
          <p>
            Now on to the legal details:
            <br />
            <br />
            This document describes the information that we (“Sourcery AI
            Limited”) collect when you interact with our website and services.
          </p>
        </div>

        <div>
          <h3 className="text-2xl py-6 mt-3">Information we collect</h3>
          <p className="pb-5">
            We are the data controller for the following information:
          </p>
          <ul className="list-inside list-disc gap-3">
            <li className="mb-5">
              Information about yourself that you voluntarily provide to us such
              as your name, email address and other contact information.
            </li>
            <li className="mb-5">
              Anonymised usage data collected by our services. These include
              information on your OS version and the versions of our services
              that you use, as well as anonymised reports of which types of
              refactorings have been suggested and accepted. We do not collect
              information on your code.
            </li>
            <li className="mb-5">
              Anonymised error reports provided by the third-party Sentry.io
              error reporting service, which is fully GDPR and EU-US Privacy
              Shield compliant.
            </li>
            <li className="mb-5">
              Information provided to us by cookies and related technologies
              such as Google Analytics.
            </li>
            <li className="mb-5">
              Code and conversation histories sent through the Coding Assistant
              to third-party LLM providers (such as OpenAI, Anthropic, etc.)
            </li>
          </ul>
        </div>

        <div>
          <h3 className="text-2xl py-6 mt-3">More on cookies</h3>
          <p>
            A cookie is a string of information that a website stores on a
            visitor
            {"'"}s computer, and that the visitor{"'"}s browser provides to the
            Service each time the visitor returns. Sourcery uses cookies to help
            Sourcery identify and track visitors. Sourcery visitors who do not
            wish to have cookies placed on their computers should set their
            browsers to refuse cookies when using Sourcery{"'"}s website.
          </p>
        </div>

        <div>
          <h3 className="text-2xl py-6 mt-3">
            What we use the information for
          </h3>
          <p>
            Sourcery collects information to provide a better service to all our
            users and visitors. We use the information to provide, maintain,
            protect and improve our website and services. If you have signed up
            to receive updates from us we will use your information to
            periodically contact you with information about our products and
            services. Our legal basis for processing the information we are the
            data controller for, is the implicit consent that you provide when
            submitting your information through a website form or emailing us.
            In some circumstances, we may also process information on the basis
            of our legitimate interest in improving our service.
          </p>
        </div>

        <div>
          <h3 className="text-2xl py-6 mt-3">Third part service providers</h3>
          <p>
            Sourcery uses services from auth0, GitHub, and Google to allow users
            to create and log into accounts. No additional information is shared
            with these service providers outside of the information required to
            create and log into an account.
            <br></br>
            <br></br>
            Sourcery uses payment services from Stripe to process payments for
            Sourcery Pro and Team services. No additional information is shared
            with Stripe outside of the information required to create and manage
            invoices, payments, and subscriptions.
            <br></br>
            <br></br>
            Sourcery uses analytics services from Google Analytics. All
            information sent to Google Analytics is anonymized.
          </p>
        </div>

        <div>
          <h3 className="text-2xl py-6 mt-3">Sharing the information</h3>
          <p>
            We do not share the information with any external third parties,
            except as detailed in this document.
          </p>
        </div>

        <div>
          <h3 className="text-2xl py-6 mt-3">Storage and security</h3>
          <p>
            Sourcery uses third-party vendors and hosting partners to provide
            the necessary hardware, software, networking, storage, and related
            technology required to run the Service. You understand that although
            you retain full rights to your data, it may be stored on third-party
            storage and transmitted through third-party networks. We take
            careful technical measures to ensure that your information is secure
            and inaccessible to unauthorized parties. We also continuously work
            on new features to improve security. We retain personal data for as
            long as necessary for the purpose for which the personal data was
            collected, or for such longer period required by law or otherwise
            necessary to defend or exercise our legal rights. At the end of this
            period (or expiry of our backup archive retention period if later),
            we will either delete or anonymise the personal data.
          </p>
        </div>

        <div>
          <h3 className="text-2xl py-6 mt-3">Your rights</h3>
          <p>
            You have the following rights in respect of your personal data:
            <ul className="list-inside list-disc gap-3">
              <li className="mb-5">
                Access - Request information about personal data we hold on you
              </li>
              <li className="mb-5">
                Rectification - Correct or update your personal data
              </li>
              <li className="mb-5">
                Objection - Object to processing of personal data based on our
                legitimate interests
              </li>
              <li className="mb-5">
                Restriction - Ask us to retain but otherwise stop actively
                processing personal data
              </li>
              <li className="mb-5">
                Erasure - Request deletion of your personal data
              </li>
              <li className="mb-5">
                Portability - Request your personal data in machine-readable
                format
              </li>
              <li className="mb-5">
                Withdrawal - Withdraw consent for future processing, if we
                process based on your consent
              </li>
              <li className="mb-5">
                Decisions - To not be subject to significant decisions based
                solely on automated processing
              </li>
              <li className="mb-5">
                Complaints - Contact the Information Commissioner{"'"}s Office
                with complaints about our processing
                (https://ico.org.uk/concerns)
              </li>
            </ul>
            Depending on the circumstances, we may need to verify your identity
            before complying with your request and we may not always be able to
            comply with your request in full (for example when producing your
            information may reveal another person{"'"}s personal data or when
            there is an overriding interest or conflicting legal obligation).
          </p>
        </div>

        <div>
          <h3 className="text-2xl py-6 mt-3">Updates</h3>
          <p>
            Although most changes are likely to be minor, Sourcery may change
            its Privacy Policy from time to time, and in Sourcery{"'"}s sole
            discretion. Sourcery encourages visitors to frequently check this
            page for any changes to its Privacy Policy. Your continued use of
            this site after any change in this Privacy Policy will constitute
            your acceptance of such change.
          </p>
        </div>

        <div>
          <h3 className="text-2xl py-6 mt-3">Contact us</h3>
          <p>
            If you have any questions about this Privacy Policy or want to
            officially request, review, delete or anything else about your
            personal data, please contact us at info@sourcery.ai and we will
            work with you to identify and follow the appropriate process to
            satisfy your request.
          </p>
        </div>
      </div>
    </div>
  </>
)

export default PrivacyPage
